.logon_container {
    width: 100%;
    max-width: 1120px;
    height: 100vh;
    margin:0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .form {
        width: 100%;
        max-width: 350px;
        margin-right: 30px;

        form {
            margin-top: 100px;
        }

        form h1 {
            font-size: 31px;
            margin-bottom: 32px;
        }

        form a svg {
            margin-right: 8px;
        }
    }
}
